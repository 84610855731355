import {
    DurationLikeObject,
    DateTimeUnit,
    DateTime as Luxon,
    DurationObjectUnits,
} from 'luxon';

export enum Format {
    Date = 'yyyy-MM-dd',
    DateTime = 'yyyy-MM-dd HH:mm:ss',
    DayMonthYearAtTimeFormat = "dd MMM yyyy 'at' hh:mma",
    DateBritish = 'dd/MM/yyyy',
    DateBritishDashed = 'dd-MM-yyyy',
    MonthAndYear = 'MMMM yyyy',
}

export enum DateType {
    ISO,
    SQL,
}

/**
 * Implement luxon form datetime manipulation.
 * Update this class to add more functionality as needed.
 * This class is a wrapper around luxon datetime object.
 * If we need to later switch to different library,
 * basically update this class without changing the
 * exposed functions.
 */
export class DateTime {
    private date: Luxon;

    private dateString: string;

    /**
     *
     * Basically adds ordinal suffix e.g. 1st, 2nd
     * in the date string, using this as luxon does
     * not natively support this.
     *
     * @param {number} day day in a month starts from 1
     * @return {string}
     */
    private getOrdinalSuffix(day: number): string {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    /**
     * This is private as we don't want/need to create
     * object using new operator.
     *
     * @param {Luxon} date luxon object
     */
    public constructor(date: Luxon) {
        this.date = date;
    }

    /**
     * Checks if the current DateTime instance holds a valid Luxon date.
     *
     * @return {boolean} Returns true if the date is valid, false otherwise.
     */
    public isValid(): boolean {
        return this.date.isValid;
    }

    /**
     * Creates an object from the start of provided unit.
     * For example.
     *
     * If hour is passed as unit, datetime would be start of the current
     * hour
     *
     * @param {DateTimeUnit} unit unit to which the time needs to start from
     * @return {DateTime}
     */
    static startOf(unit: DateTimeUnit) {
        return new DateTime(Luxon.utc().startOf(unit));
    }

    /**
     * Creates an object using current local datetime
     *
     * @return {DateTime}
     */
    static now() {
        return new DateTime(Luxon.utc());
    }

    /**
     * Creates an object using the supplied datetime in JSDateTime
     *
     * @param {Date} date JSDate object to create DateTime object from
     * @return {DateTime}
     */
    static fromDate(date: Date) {
        return new DateTime(Luxon.fromJSDate(date));
    }

    /**
     * Creates an object using the string date and date
     * format string
     *
     * @param {string} date Date in string form
     * @param {string} format Format of the string date
     * @return {DateTime}
     */
    static parseCustom(date: string, format: string = Format.Date) {
        if (typeof date === 'undefined' || date == null)
            throw new Error('Invalid date');

        const parsedDate = Luxon.fromFormat(date, format);

        if (!parsedDate.isValid) {
            throw new Error(`Invalid date: ${parsedDate.invalidExplanation}`);
        }

        return new DateTime(parsedDate);
    }

    /**
     * Creates an object using the string date and date
     * format from predefined format
     *
     * @param {string} date Date in string form
     * @param {DateTime} type Format from the predefined enum
     * @return {DateTime}
     */
    static parse(date: string, type: DateType = DateType.SQL) {
        if (typeof date === 'undefined' || date == null)
            throw new Error('Invalid date');

        let parsedDate;

        switch (type) {
            case DateType.ISO:
                parsedDate = Luxon.fromISO(date);
                break;
            case DateType.SQL:
                parsedDate = Luxon.fromSQL(date);
                break;
        }

        if (!parsedDate.isValid) {
            throw new Error(`Invalid date: ${parsedDate.invalidExplanation}`);
        }

        return new DateTime(parsedDate);
    }

    /**
     * Returns datetime in Js Date format
     *
     * @return {Date}
     */
    public get() {
        return this.date.toJSDate();
    }

    /**
     * Returns datetime in string format
     *
     * @return {string}
     */
    public toString() {
        return this.dateString;
    }

    /**
     * Formats the datetime to string and returns
     * the DateTime object
     *
     * @param {string} format Format to parse date to
     * @return {DateTime}
     */
    public format(format: string = Format.Date) {
        if (!this.date.isValid) {
            throw new Error(`Invalid date: ${this.date.invalidExplanation}`);
        }

        this.dateString = this.date.toFormat(format);
        this.dateString = this.dateString.replace(/AM|PM/g, (str) =>
            str.toLowerCase()
        );

        return this;
    }

    /**
     * Adds ordinal suffix to the alread formatted datetime string.
     * this only works if day is the first part of the date string.
     *
     * @return {DateTime}
     */
    public addOrdinalSuffix() {
        this.dateString = this.dateString.replace(/(\d{2})/, (match) => {
            const day = parseInt(match, 10);
            return `${day}${this.getOrdinalSuffix(day)}`;
        });

        return this;
    }

    /**
     * Adds a specified number of days to the current date.
     *
     * @param {Object} options - An object containing the number of days to add.
     * @param {number} options.years - The number of years to add.
     * @param {number} options.quarters - The number of quarters to add.
     * @param {number} options.months - The number of months to add.
     * @param {number} options.weeks - The number of weeks to add.
     * @param {number} options.days - The number of days to add.
     * @param {number} options.hours - The number of hours to add.
     * @param {number} options.minutes - The number of minutes to add.
     * @param {number} options.seconds - The number of seconds to add.
     * @param {number} options.milliseconds - The number of milliseconds to add.
     *
     * @return {DateTime}
     */
    public add(options: DurationLikeObject) {
        this.date = this.date.plus(options);

        return this;
    }

    /**
     * Compares provided datetime with current i.e. datetime
     * the object is holding and returns the result as a boolean.
     *
     * @param {Date} date2 JSDate to compare current datetime with
     * @param {boolean} inclusive Whether to include current datetime when
     * doing the comparision
     *
     * @return {boolean}
     */
    public isBefore(date2: Date, inclusive = false) {
        if (typeof date2 === 'undefined' || date2 == null) {
            return false;
        }

        const datetime2 = Luxon.fromJSDate(date2);

        if (!datetime2.isValid) {
            throw new Error(`Invalid date`);
        }

        if (inclusive) return this.date <= datetime2;

        return this.date < datetime2;
    }

    /**
     * Compares provided datetime with current i.e. datetime
     * the object is holding and returns the result as provided unit.
     *
     * @param {Date} date2 JSDate to compare current datetime with
     * @param {string} unit unit in which the difference should be calculated into
     * @param {boolean} absolute whether the difference should always be positive
     *
     * @return {Object}
     */
    public diff(date2: Date, unit: keyof DurationLikeObject, absolute = true) {
        if (typeof date2 === 'undefined' || date2 == null) {
            throw new Error('Invalid date');
        }

        const datetime2 = Luxon.fromJSDate(date2);

        if (!datetime2.isValid) {
            throw new Error(`Invalid date`);
        }

        const diff = this.date.diff(datetime2, unit).toObject();

        // truncate the values to whole numbers
        if (absolute) {
            Object.keys(diff).forEach((key) => {
                const objKey = key as keyof DurationObjectUnits;
                // eslint-disable-next-line security/detect-object-injection
                if (diff.hasOwnProperty(objKey) && diff[objKey] != null) {
                    // eslint-disable-next-line security/detect-object-injection
                    diff[objKey] = Math.trunc(diff[objKey]);
                }
            });
        }

        return diff;
    }
}
